
module.exports = {
    en : {
        '27' : `<p>Manchester United Football Team is an English Premier League football club headquartered in Old Trafford, Greater Manchester. Manchester United is one of the most popular clubs in English football history, having won 18 league crowns, four League Cups, and a record 11 FA Cups. The team has also won three European Cups and is the only one to have won the Premier League, FA Cup, and UEFA Champions League all in the same season in 1998–99.</p>
                  <p>Manchester United is one of the world's wealthiest and most popular football clubs. According to reports, the club is valued at £1.19 billion, making it the most expensive football club in the world.</p>
                  `,
        '24' : `<p>Chelsea Football Club is an accomplished English football club situated in west London. They were founded in 1905 and play in the Premier League. They have spent most of their existence in the top rank of English football. Chelsea has won the English Premier League four times, the FA Cup six times, and the League Cup four times. They've also had success in Europe, having won the UEFA Winners' Cup two times.</p>`,
        '25' : `<p>Liverpool Football Club is an English Premier League professional football club based in Liverpool, England. They are one of the most successful clubs in English football history, having won a joint record of 18 league titles, seven FA Cups, and a record seven League Cups. Liverpool is the most famous English club in the European football tournament, having won five European Cups, the most recent of which was in 2005, as well as three UEFA Cups. With the most international trophies won, the club presently stands third in Europe and sixth in the globe.</p>`,
        '82' : `<p>Real Madrid Club de Fútbol, or simply Real Madrid, is a pro football club based in Madrid, Spain. It is the most successful team in Spanish football and was named the most prolific club in the twentieth century by FIFA, having won a record 31 La Liga titles, 17 Spanish "Copa del Rey" Cups, eight Spanish Super Cups, a record nine UEFA Champions Leagues, two UEFA Cups, one UEFA Supercup, and three Intercontinental Cups. In 2008, the club was the world's richest football club in terms of income (€401 million) and the second most valued (more than €950 million).</p>`,
        '84' : `<p>Barcelona is a Spanish competitive football club headquartered in Barcelona, Spain. It is known simply as Barcelona and colloquially as Barça. With annual revenue of €366 million, FC Barcelona is the world's second-richest football club in terms of income. They have won 20 La Liga championships, a remarkable 25 Spanish Cups, nine Spanish Super Cups, and two League Cups, making them the most prominent club in Spanish football in terms of overall honors. They are also one of the world's most successful clubs, having won 14 recognized trophies, including 10 UEFA tournaments.</p> 
                   <p>Barcelona is the only European club that has competed in continental football every year since 1955. Barcelona has become the first team in Spain to win the triple of La Liga, Copa del Rey, and Champions League in 2009. In the same year, they became the first football club in history to win six consecutive competitions, completing the sextuple, which included the aforementioned treble as well as the UEFA Super Cup, Spanish Super Cup, and FIFA Club World Cup.</p>
                   `
    },
    th : {
        '27' : `<p>สโมสรฟุตบอลแมนเชสเตอร์ยูไนเต็ด เป็นสโมสรจากเมืองแมนเชสเตอร์ ประเทศอังกฤษ ปัจจุบันแข่งขันในพรีเมียร์ลีกซึ่งเป็นลีกสูงสุดของฟุตบอลอังกฤษ และเป็นทีมที่คว้าแชมป์ลีกสูงสุดอังกฤษได้มากที่สุด 20สมัย สโมสรมีฉายา "ปีศาจแดง" ก็ตั้งเมื่อปี ค.ศ 1878 มีชื่อเดิมว่านิวตันฮีตแอลวายอาร์ต่อมาเปลี่ยนชื่อเป็นแมนเชสเตอร์ยูไนเต็ดใน ค.ศ. 1902 และย้ายไปเล่นที่สนามเหย้าปัจจุบันอย่างโอลด์แทรฟฟอร์ดใน ค.ศ. 1910</p>
                  <p>แมนเชสเตอร์ยูไนเต็ด ถือว่าเป็นทีมที่ประสบความสำเร็จมากที่สุดของเกาะอังกฤษ และเป็นสโมสรฟุตบอลแรกของอังกฤษที่ชนะเลิศยูโรเปียนคัพภายใต้การคุมทีมของแมตต์ บัสบี นอกจากนี้แมนเชสเตอร์ยูไนเต็ดชนะเลิศถ้วยรางวัลมากมาย โดยชนะเลิศลีก 20 สมัย, เอฟเอคัพ 12 สมัย, ลีกคัพ 5 สมัย และเอฟเอคอมมิวนิตีชีลด์ 21 สมัย(สถิติสูงสุด), ยูโรเปียนคัพ/ยูฟ่าแชมเปียนส์ลีก 3 สมัย, ยูฟ่ายูโรปาลีก 1 สมัย, ยูฟ่าคัพวินเนอร์สคัพ 1 สมัย, ยูฟ่าซูเปอร์คัพ 1 สมัย, อินเตอร์คอนติเนนตัลคัพ 1 สมัย และฟุตบอลชิงแชมป์สโมสรโลก 1 สมัย โดยในฤดูกาล 1998–99 สโมสรกลายเป็นทีมแรกในประวัติศาสตร์ฟุตบอลอังกฤษที่คว้าทริปเปิลแชมป์ระดับทวีปยุโรป</p>
                  `,
        '24' : `<p>สโมสรฟุตบอลเชลซี สโมสรฟุตบอลอาชีพที่ตั้งอยู่ในเขตฟูลัม, ลอนดอน  โดยในตอนแรกใช้ชื่อว่า ฟูแลมเอฟซี แต่ไปซ้ำกันกับสโมสรฟุตบอลฟูแลม เลยต้องเปลี่ยนชื่อไปใช้ เคนชิงตันเอฟซี,สแตมฟอร์ดบริดจ์เอฟซี แต่สุดท้ายก็เปลี่ยนชื่อมาเป็น ลอนดอน เอฟซี และในที่สุดก็ลงเอยเปลี่ยนเป็นเชลซีเอฟซี จนถึงปัจจุบัน สโมสรเชลซีได้เลื่อนชั้นมาเล่นดิวิชั่น1ครั้งแรกในซีซั่นที่2หลังการก่อตั้งสโมสร (ฤดูกาล 1906-07) และหลังจากนั้นมา เชลซีก็ได้เล่นอยู่บนลีกสูงสุดของประเทศเป็นส่วนใหญ่  </p>
                  <p>สโมสรฟุตบอลเชลซีก่อตั้งขึ้นเมื่อวันที่ 10 มีนาคม ค.ศ. 1905 การเปลี่ยนแปลงครั้งใหญ่ของสโมสรเกิดขึ้นเมื่อปี ค.ศ 2004 หลังจาก มหาเศรษฐีชาวรัสเซีย โรมัน อับราโมวิช  ได้เข้ามา “เทคโอเวอร์” สโมสรในราคา 140 ล้านปอนด์ และได้ทุ่มซื้อสตาร์ดังมามากมาย จนทุกวันนี้เชลซีกลายเป็นหนึ่งในสโมสรที่ประสบความสำเร็จมากที่สุดของอังกฤษ โดยคว้าแชมป์จากการแข่งขันมากมาย ชนะเลิศดิวิชันหนึ่ง/พรีเมียร์ลีก 6สมัย ,ชนะเลิศลีกรองดิวิชันสอง 2 สมัย , เอฟเอคัพ 2สมัย, ลีกคัพ 5สมัย , คอมมิวนิตีชีลด์ 4สมัย, ทั้งนี้เชลซียังประสบควาสำเร็จในระดับทวีปโดยพวกเขาคว้า ยูฟ่าแชมเปียนส์ลีก 2สมัย, ยูฟ่ายูโรปาลีก 2มัย , ยูฟ่าคัพวินเนอร์สคัพ 2สมัย , ยูฟ่าซูเปอร์คัพ 2 สมัย และ ฟีฟ่าคลับเวิลด์คัพ อีก 1สมัย</p>
                  `,
        '25' : `<p>สโมสรฟุตบอลลิเวอร์พูลเป็นสโมสรฟุตบอลอาชีพตั้งอยู่ที่เมืองลิเวอร์พูล ประเทศอังกฤษ แข่งขันอยู่ในพรีเมียร์ลีก ลีกสูงสุดของของประเทศอังกฤษ ก่อตั้งโดย จอห์น โฮลดิง เมื่อปี ค.ศ. 1892 ลิเวอร์พูลใช้สนามแอนฟีลด์เป็นสนามเหย้าตั้งแต่ก่อตั้งสโมสร</p>
                  <p>ลิเวอร์พูลเป็นทีมจากอังกฤษที่ประสบความสำเร็จในเวทียูโรปหรือระดับนานาชาติมากที่สุด โดยชนะเลิศยูฟ่าแชมเปียนส์ลีก 6 สมัย, ยูฟ่าคัพ 3 สมัย, ยูฟ่าซูเปอร์คัพ 4 สมัย และฟีฟ่าคลับเวิลด์คัพ 1 สมัย ส่วนในระดับประเทศ ลิเวอร์พูลคว้าลีกสูงสุดไปแล้วทั้งสิ้น 19สมัย(เป็นรองแค่แมนเชสเตอร์ยูไนเต็ด1สมัย) ชนะเลิศลีกรองดิวิชันสอง 4 สมัย ,เอฟเอคัพ 7สมัย,ลีกคัพ 8สมัย  , คอมมิวนิตีชีลด์ 15สมัย</p>
                  <p>ลิเวอร์พูลเป็นหนึ่งในสโมสรที่มีผู้สนับสนุนมากที่สุดในโลกโดยเฉพาะประเทศไทยที่มีสาวก หงส์แดง มากเป็นอันดับต้นๆ ลิเวอร์พูลมีฉายา "เดอะเรดส์" (The Reds) ส่วนฉายาที่ชาวไทยนิยมเรียกคือ "หงส์แดง" ลิเวอร์พูลมีเพลงประจำสโมสรคือ "ยูลล์เนฟเวอร์วอล์กอะโลน" (You'll Never Walk Alone)</p>
                 `,
        '82' : `<p>สโมสรฟุตบอลเรอัลมาดริด เป็นสโมสรฟุตบอลอาชีพตั้งอยู่ใน กรุงมาดริด ประเทศสเปน และเป็นที่มีชื่อเสียงมากที่สุดอันดับต้นๆของโลก ก่อตั้งขึ้นเมื่อปี ค.ศ. 1902 มีฉายา ราชันชุดขาว เล่นในลีกสูงสุดลาลิกา สเปน และสนามเหย้าของสโมสรคือสนามซานเตียโก เบร์นาเบว</p>
                   <p>เรอัลมาดริด</p>
                   <p>เป็นสโมสรที่ประสบความสำเร็จมากที่สุดในประวัติศาสตร์รายการแข่งขันของยูฟ่าด้วยการคว้าแชมป์ยูฟ่าแชมเปียนส์ลีก 13 สมัย, ยูฟ่าคัพ 2 สมัย และ ยูฟ่า ซูเปอร์คัพ 4 สมัย ส่วนในการแข่งขันภายในประเทศ สโมสรยังประสบความสำเร็จมากมายประกอบด้วย สถิติแชมป์ลาลีกา 34 สมัย , โคปาเดลเรย์ 19 สมัย, ซูเปอร์โคปาเดเอสปาญา 11 สมัย และ แชมป์สโมสรโลก 4สมัย</p>
                   `,
        '84' : `<p>สโมสรฟุตบอลบาร์เซโลน่า หรือเรียกสั้นๆว่า บาร์ซ่า ตั้งอยู่ที่เมืองบาร์เซโลน่า แคว้นกาตาลุญญา ประเทศสเปน เล่นอยู่ในลาลิกาและเป็น 1 ใน 3 สโมสรที่ไม่เคยตกชั้นจากลีกสูงสุดของสเปน ก่อตั้งในชื่อ ฟุตบอลคลับบาร์เซโลนา ใน ค.ศ. 1899 โดยกลุ่มของนักฟุตบอลชาวสวิส, อังกฤษ และสเปน นำโดย ฌูอัน กัมเป</p>
                    <p>บาร์เซโลนาเป็นเป็นสโมสรที่ประสบความสำเร็จสูงในการแข่งขันฟุตบอลลีกของสเปนในแง่ของจำนวนถ้วยรางวัลภายในประเทศ โดยชนะเลิศการแข่งลาลิกา 26 สมัย, โกปาเดลเรย์ 31 สมัย, ซูเปร์โกปาเดเอสปัญญา 13 สมัย, โกปาเอบาดัวร์เต 3 สมัย และได้รางวัลโกปาเดลาลิกา 2 สมัย นอกจากนี้ยังเป็นสโมสรที่ประสบความสำเร็จสูงในการแข่งขันระดับทวีปของยุโรป โดยชนะเลิศรายการยูฟ่าแชมเปียนส์ลีก 5 สมัย, ยูฟ่าคัพวินเนอร์สคัพ 4 สมัย, ยูฟ่าซูเปอร์คัพ 5 สมัย และฟีฟ่าคลับเวิลด์คัพอีก 3 สมัย พวกเขายังมีสถิติชนะเลิศรายการอินเตอร์-ซิตีส์แฟส์คัพ 3 สมัย ซึ่งถือถ้วยต้นแบบของการแข่งขันยูฟ่าคัพ</p>
                    <p>ทั้งนี้บาเซโลน่า ยังมีศูนย์ฝึก ลา มาเซีย ที่ได้เพาะบ่มนักเตะเวิลด์คลาสมากมาย ขึ้นมาสู่ทีมชุดใหญ่ของบาร์ซ่า เพราะเป็นศูนย์ฝึกเดียวของโลกใบนี้ที่สามารถสร้างแคนดิเดทชิงรางวัลบัลลงดอร์ทั้ง 3 คนในปีเดียวกันได้ นั่นคือ ลิโอเนล เมสซี่, อันเดรส อิเนียสต้า และ ชาบี้ เอร์นานเดซ เมื่อปี 2010 และผู้เล่นของทีมบาร์เซโลน่าหลายต่อหลายคนยังประสบความสำเร็จทางด้านผลงานส่วนตัวซึ่งเป็นเกียรติประวัติระดับโลกมากมายเช่น ได้รับรางวัลบาลงดอร์ 12 ครั้ง</p>
                    `
    },
    kh : {
        '27' : `<p>Manchester United គឺជាក្លឹបបាល់ទាត់នៅក្របខណ្ឌអង់គ្លេស Premier League ដែលមានទីស្នាក់ការកណ្តាលនៅ Old Trafford ទីក្រុង Manchester ។ Manchester United គឺជាក្លឹបដ៏ពេញនិយមបំផុតមួយក្នុងប្រវត្តិសាស្ត្របាល់ទាត់អង់គ្លេស ដោយបានឈ្នះពានរង្វាន់លីគចំនួន ២០ ដង ពាន League Cup ចំនួន ៥ ដង និង FA Cup ចំនួន 12 ដង។ ក្រុមនេះក៏បានឈ្នះពានរង្វាន់អឺរ៉ុបចំនួនបីផងដែរ ហើយពួកគេជាក្រុមតែមួយគត់ដែលបានឈ្នះ Premier League, FA Cup និង UEFA Champions League ទាំងអស់ក្នុងរដូវកាលតែមួយក្នុងឆ្នាំ 1998-99 ។</p>
                  <p>Manchester United ក៏ជាក្លឹបបាល់ទាត់ដែលមានទ្រព្យសម្បត្តិ និងភាពពេញនិយមបំផុតមួយរបស់ពិភពលោក។ យោងតាមរបាយការណ៍ ក្លឹបនេះមានតម្លៃ 1.19 ពាន់លានផោន ដែលធ្វើឱ្យវាក្លាយជាក្លឹបបាល់ទាត់ថ្លៃបំផុតនៅលើពិភពលោក។</p>
                  `,
        '24' : `<p>Chelsea Football Club គឺជាក្លឹបបាល់ទាត់អង់គ្លេសដែលមានទីតាំងនៅភាគខាងលិចទីក្រុងឡុងដ៍។ ពួកគេត្រូវបានបង្កើតឡើងក្នុងឆ្នាំ 1905 ហើយលេងនៅក្របខណ្ឌ Premier League ។ ពួកគេបានចំណាយពេលភាគច្រើននៃប្រវត្តិសាស្ត្ររបស់ពួកគេនៅចំណាត់ថ្នាក់កំពូលនៃលីគបាល់ទាត់អង់គ្លេស។ Chelsea ឈ្នះ Premier League ៦ដង FA Cup ៨ដង និង League Cup ៥ដង។ ពួកគេ​ក៏​ធ្លាប់​ទទួល​បាន​ជោគជ័យ​នៅ​អឺរ៉ុប​ដែរ ដោយ​បាន​ឈ្នះ UEFA Winners' Cup ពីរ​ដង។</p>`,
        '25' : `<p>Liverpool Football Club គឺជាក្លឹបបាល់ទាត់អាជីពរបស់អង់គ្លេស Premier League ដែលមានមូលដ្ឋាននៅទីក្រុង Liverpool ប្រទេសអង់គ្លេស។ ពួកគេគឺជាក្លឹបមួយក្នុងចំណោមក្លឹបដែលទទួលបានជោគជ័យបំផុតក្នុងប្រវត្តិសាស្ត្របាល់ទាត់អង់គ្លេស ដោយបានឈ្នះកំណត់ត្រារួមគ្នានៃពានរង្វាន់លីកចំនួន 19 ដង FA Cup ចំនួន 8ដង និងកំណត់ត្រា League Cup ចំនួ9ដង។ Liverpool គឺជាក្លឹបអង់គ្លេសដ៏ល្បីបំផុតក្នុងការប្រកួតបាល់ទាត់ទ្វីបអឺរ៉ុប ដោយបានឈ្នះពានរង្វាន់អឺរ៉ុបចំនួន ៦ ដង ដែលចុងក្រោយបំផុតគឺនៅឆ្នាំ 2019 ក៏ដូចជា UEFA Cup ចំនួនបីផងដែរ។ ជាមួយនឹងការឈ្នះពានរង្វាន់អន្តរជាតិច្រើនជាងគេ ក្លឹបបច្ចុប្បន្នឈរនៅចំណាត់ថ្នាក់លេខ 2 នៅអឺរ៉ុប និងទី 6 នៅលើពិភពលោក។</p>`,
        '82' : `<p>Real Madrid Club de Fútbol ឬហៅសាមញ្ញថា Real Madrid គឺជាក្លឹបបាល់ទាត់ដែលមានមូលដ្ឋាននៅទីក្រុង Madrid ប្រទេសអេស្ប៉ាញ។ វាជាក្លិបដែលជោគជ័យបំផុតក្នុងបាល់ទាត់អេស្ប៉ាញ ហើយត្រូវបាន FIFA ដាក់ឈ្មោះថាជាក្លឹបដែលរីកចម្រើនបំផុតក្នុងសតវត្សទី 20 ដោយបានឈ្នះកំណត់ត្រា La Liga ចំនួន 35 ដង, Spanish "Copa del Rey" Cups 19 ដង, Spanish Super Cups 12ដង, UEFA Champions League ១៤ដង, UEFA Cup ២ដង , UEFA Supercup ៤ដង, និង Intercontinental Cup ៣ដង។ ក្នុងឆ្នាំ 2008 ក្លឹបនេះគឺជាក្លឹបបាល់ទាត់ដែលមានជាងគេបំផុតនៅលើពិភពលោកដោយមានប្រាក់ចំណូល 401 លានអឺរ៉ូ និងជាក្លិបមានតម្លៃថ្លៃជាងគេទីពីរលើលោកគឺជាង 950 លានអឺរ៉ូ។</p>`,
        '84' : `<p>Barcelona គឺជាក្លឹបបាល់ទាត់របស់អេស្ប៉ាញដែលមានទីស្នាក់ការកណ្តាលនៅទីក្រុង Barcelona ប្រទេសអេស្ប៉ាញ។ វា​ត្រូវ​បាន​គេ​ស្គាល់​យ៉ាង​សាមញ្ញ​ថា Barcelona និង​ហៅ​កាត់​ថា Barça។ ជាមួយនឹងប្រាក់ចំណូលប្រចាំឆ្នាំ 366 លានអឺរ៉ូ FC Barcelona គឺជាក្លឹបបាល់ទាត់ដែលមានជាងគេទីពីររបស់ពិភពលោក។ ពួកគេបានឈ្នះជើងឯក La Liga ចំនួន 26 ដង Spanish Cup 31ដង Spanish Super Cup ៣ដង និង League Cup ចំនួន 2 ដែលធ្វើឱ្យពួកគេក្លាយជាក្លឹបដែលមានកិត្តិយសលេចធ្លោបំផុតនៅអេស្ប៉ាញ។ ពួកគេក៏ជាក្លឹបជោគជ័យបំផុតមួយរបស់ពិភពលោក ដោយបានឈ្នះពានរង្វាន់ចំនួន 17 ដោយក្នុងរួមទាំងការប្រកួត UEFA ចំនួន 14 ផងដែរ។</p> 
                   <p>Barcelona គឺជាក្លឹបអឺរ៉ុបតែមួយគត់ដែលបានចូលរួមប្រកួតបាល់ទាត់ទ្វីបជារៀងរាល់ឆ្នាំចាប់តាំងពីឆ្នាំ 1955។ Barcelona បានក្លាយជាក្រុមដំបូងគេក្នុងប្រទេសអេស្ប៉ាញដែលបានឈ្នះពានរង្វាន់ធំៗទាំង៣ គឺ La Liga, Copa del Rey និង Champions League ក្នុងឆ្នាំ 2009។ ក្នុងឆ្នាំដដែលនោះ ពួកគេបានក្លាយជា ក្លឹបបាល់ទាត់ដំបូងគេក្នុងប្រវត្តិសាស្ត្រដែលឈ្នះការប្រកួតចំនួនប្រាំមួយជាប់ៗគ្នា ដែលរួមទាំងពានរង្វាន់ដូចជា UEFA Super Cup, Spanish Super Cup និង FIFA Club World Cup ។</p>
                   `
    },
    cn : {
        '27' : `<p>曼联足球队是一支英格兰超级联赛的足球俱乐部，总部位于大曼彻斯特的老特拉福德。 曼联是英格兰足球历史上最受欢迎的俱乐部之一，赢得了 18 次联赛冠军、4 次联赛杯冠军和创纪录的 11 次足总杯冠军。 球队还赢得了三座欧洲杯冠军，并且是唯一一支在 1998-99 赛季同时赢得英超联赛、足总杯和欧洲冠军联赛冠军的球队。</p>
                  <p>曼联是世界上最富有和最受欢迎的足球俱乐部之一。 据报道，该俱乐部价值11.9亿英镑，成为世界上最昂贵的足球俱乐部。</p>
                  `,
        '24' : `<p>切尔西足球俱乐部是位于伦敦西部的一家成就卓著的英格兰足球俱乐部。 他们成立于 1905 年，效力于英超联赛。 他们大部分时间都在英格兰足球的顶级联赛中度过。 切尔西四次夺得英超联赛冠军，六次夺得足总杯冠军，四次夺得联赛杯冠军。 他们在欧洲也取得了成功，两次赢得欧洲优胜者杯。</p>`,
        '25' : `<p>利物浦足球俱乐部是一家位于英格兰利物浦的英超联赛职业足球俱乐部。 他们是英格兰足球历史上最成功的俱乐部之一，共赢得了 18 次联赛冠军、7 次足总杯冠军和 7 次联赛杯冠军的联合记录。 利物浦是欧洲足球锦标赛中最著名的英格兰俱乐部，赢得了五次欧洲杯，最近一次是在 2005 年，以及三次欧洲联盟杯。 俱乐部赢得了最多的国际奖杯，目前在欧洲排名第三，在全球排名第六。</p>`,
        '82' : `<p>皇家马德里足球俱乐部，简称皇家马德里，是一家位于西班牙马德里的职业足球俱乐部。 它是西班牙足球最成功的球队，被国际足联评为 20 世纪最多产的俱乐部，赢得了创纪录的 31 次西甲冠军，17 次西班牙“国王杯”，8 次西班牙超级杯，创纪录的 9 次欧足联冠军 欧冠、两届欧洲联盟杯、一场欧洲超级杯和三届洲际杯。 2008 年，俱乐部是世界上收入最高的足球俱乐部（4.01 亿欧元），市值第二高（超过 9.5 亿欧元）。</p>`,
        '84' : `<p>巴塞罗那是一家西班牙竞技足球俱乐部，总部位于西班牙巴塞罗那。它被简称为巴塞罗那，通俗地称为巴萨。巴塞罗那足球俱乐部的年收入为 3.66 亿欧元，是全球收入第二富有的足球俱乐部。他们赢得了 20 次西甲联赛冠军，25 次西班牙杯冠军，9 次西班牙超级杯冠军和 2 次联赛杯冠军，使他们成为西班牙足球综合荣誉最突出的俱乐部。他们也是世界上最成功的俱乐部之一，赢得了 14 座公认的奖杯，其中包括 10 场欧足联锦标赛。</p> 
                   <p>巴塞罗那是唯一一家自1955年以来每年都参加欧洲大陆足球比赛的欧洲俱乐部。2009年，巴塞罗那成为西班牙第一支赢得西甲、国王杯和欧冠三冠王的球队。同年，他们成为历史上第一个连续六次夺冠的足球俱乐部，完成了六连冠，其中包括上述三冠王以及欧洲超级杯、西班牙超级杯和国际足联世俱杯。</p>
                   `
    },
    pt : {
        '27' : `<p>O Manchester United Football Team é um clube de futebol da Premier League Inglesa, com sede em Old Trafford, na Grande Manchester. O Manchester United é um dos clubes mais populares da história do futebol Inglês, tendo conquistado 18 campeonatos, quatro Taças da Liga e um recorde de 11 Taças de Inglaterra. A equipa também ganhou três Taças dos Campeões Europeus e é a única a ter ganho a Premier League, a FA Cup e a UEFA Champions League na mesma época, em 1998-99.</p>
                  <p>O Manchester United é um dos clubes de futebol mais ricos e mais populares do mundo. De acordo com os relatórios, o clube está avaliado em 1,19 mil milhões de libras, o que faz dele o clube de futebol mais caro do mundo.</p>
                  `,
        '24' : `<p>O Chelsea Football Club é um clube de futebol Inglês situado na zona oeste de Londres. They were founded in 1905 and play in the Premier League. O clube passou a maior parte da sua existência no primeiro escalão do futebol Inglês. O Chelsea ganhou quatro vezes a Premier League Inglesa, seis vezes a Taça de Inglaterra e quatro vezes a Taça da Liga. A equipa também tem tido sucesso na Europa, tendo conquistado a Taça dos Vencedores da UEFA por duas vezes.</p>`,
        '25' : `<p>O Liverpool Football Club é um clube de futebol profissional da Primeira Liga Inglesa, com sede em Liverpool, Inglaterra. É um dos clubes mais bem sucedidos da história do futebol Inglês, tendo conquistado um recorde conjunto de 18 títulos da liga, sete Taças de Inglaterra e um recorde de sete Taças da Liga. O Liverpool é o clube Inglês mais famoso na competição Europeia de futebol, tendo ganho cinco Taças dos Campeões Europeus, a última das quais em 2005, bem como três Taças UEFA. Com o maior número de troféus internacionais conquistados, o clube ocupa atualmente o terceiro lugar na Europa e o sexto no mundo.</p>`,
        '82' : `<p>O Real Madrid Club de Fútbol, ou simplesmente Real Madrid, é um clube de futebol profissional com sede em Madrid, Espanha. É a equipa mais bem sucedida do futebol espanhol e foi considerada pela FIFA como o clube mais prolífico do século XX, tendo conquistado um recorde de 31 títulos da La Liga, 17 Taças de Espanha "Copa del Rey", oito Supertaças de Espanha, um recorde de nove Ligas dos Campeões da UEFA, duas Taças UEFA, uma Supertaça da UEFA e três Taças Intercontinentais. Em 2008, o clube era o clube de futebol mais rico do mundo em termos de receitas (401 milhões de euros) e o segundo mais valorizado (mais de 950 milhões de euros).</p>`,
        '84' : `<p>O Barcelona é um clube de futebol competitivo Espanhol, com sede em Barcelona, Espanha. É conhecida simplesmente como Barcelona e coloquialmente como Barça. Com uma faturação anual de 366 milhões de euros, o FC Barcelona é o segundo clube de futebol mais rico do mundo em termos de receitas. Venceu 20 campeonatos da La Liga, umas notáveis 25 Taças de Espanha, nove Supertaças de Espanha e duas Taças da Liga, o que faz dele o clube mais proeminente do futebol Espanhol em termos de honras gerais. É também um dos clubes mais bem sucedidos do mundo, tendo ganho 14 troféus reconhecidos, incluindo 10 torneios da UEFA.</p> 
                   <p>O Barcelona é o único clube Europeu que participa todos os anos em competições continentais de futebol desde 1955. Em 2009, o Barcelona tornou-se a primeira equipa Espanhola a conquistar a tripla La Liga, a Copa del Rey e a Champions League. No mesmo ano, tornou-se o primeiro clube de futebol da história a ganhar seis competições consecutivas, completando a sextupla, que incluía a já referida tripla, bem como a UEFA Super Cup, Spanish Super Cup, e FIFA Club World Cup.</p>
                   `
    },
}

 